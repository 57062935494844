export const userSignup = {
  name: 'app.UserSignup',
  component: 'SignupEditor',
  baseForm: {
    component: 'app.User',
  },
  store: {
    component: 'app.UserStore',
  },
};
