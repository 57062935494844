export const recoverPassword = {
  name: 'app.RecoverPassword',
  component: 'RecoverPasswordEditor',
  action: {
    component: 'Email',
    to: '{{fields.email.value}}',
    subject: 'Reset Password',
    body: '[Reset your password](https://example.com/reset-password/{{arguments.values.token}})',
  },
  store: {
    component: 'RecordStore',
    storeName: 'app.RecoverPassword',
  },
};
