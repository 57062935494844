// @ts-ignore
import compiler from 'mson/lib/compiler';

// @ts-ignore
import register from 'mson/lib/compiler/register';

// TODO: fix MSON build so can just use this instead:
// import { compiler, register } from 'mson';

// @ts-ignore
import globals from 'mson/lib/globals';

import * as components from '../../components';

// @ts-ignore
import registrar from 'mson/lib/compiler/registrar';

// @ts-ignore
import Component from 'mson/lib/component';

// @ts-ignore
import uiComponents from 'mson-react/lib/components';

// @ts-ignore
import optionalComponents from 'mson/lib/optional-components';

// @ts-ignore
import FieldEditorFormUI from 'mson-react/lib/field-editor-form';

// @ts-ignore
import FormEditorUI from 'mson-react/lib/form-editor';

// Register optional core components
// compiler.registerComponents(optionalComponents); // Use this instead of the line below after https://github.com/redgeoff/mson/pull/465 is released
Object.keys(optionalComponents).forEach((name) =>
  compiler.registerComponent(
    name,
    optionalComponents[name as keyof typeof optionalComponents]
  )
);
uiComponents.FieldEditorForm = FieldEditorFormUI;
uiComponents.FormEditor = FormEditorUI;

const appId = '101';

// TODO: in a production app the appId should be set by the path or subdomain
globals.set({ appId });

// TODO: properly set
globals.set({ reCAPTCHASiteKey: '6LdIbGMUAAAAAJnipR9t-SnWzCbn0ZX2myXBIauh' });

// Make sure we only process frontend actions
Component.setLayer('frontEnd');

export async function loadAndCompileApp(loadStatically?: boolean) {
  if (loadStatically) {
    Object.values(components).forEach((component) =>
      register(component, undefined /* REMOVE: after TS migration complete */)
    );
  } else {
    // TODO: remove any after registrar ported to TypeScript
    const clientApp = await (registrar as any).client.app.get({
      id: appId,
      includeDefinitions: true,
    });
    Object.values(clientApp.data.app.definitions).forEach((definition) =>
      register(definition, undefined /* REMOVE: after TS migration complete */)
    );
  }

  return compiler.newComponent({
    component: 'app.App',
  });
}
