export const users = {
  name: 'app.Users',
  component: 'Form',
  fields: [
    {
      name: 'users',
      label: 'Users',
      component: 'UserList',
      baseFormFactory: {
        component: 'Factory',
        product: {
          component: 'app.User',
        },
      },
      store: {
        component: 'app.UserStore',
      },
    },
  ],
};
