export const user = {
  name: 'app.User',
  component: 'User',
  isStore: true,
  fields: [
    {
      component: 'PersonNameField',
      name: 'firstName',
      label: 'First Name',
      required: true,
      block: false,
      before: 'username',
    },
    {
      component: 'PersonNameField',
      name: 'lastName',
      label: 'Last Name',
      required: true,
      before: 'username',
    },
    {
      component: 'SelectField',
      name: 'roles',
      label: 'Roles',
      multiple: true,
      options: [{ value: 'admin', label: 'Admin' }],
    },
  ],

  roles: ['standard'],

  // TODO: reuse to provide default protection for all stores. Need an Access component?
  access: {
    form: {
      create: ['admin'],
      read: ['admin', 'standard'],
      update: ['admin', 'owner'],
      archive: ['admin'],
    },
    fields: {
      password: {
        update: ['admin', 'owner'],
      },
      roles: {
        create: 'admin',
        read: 'admin',
        update: 'admin',
      },
    },
  },
};
