export const contactsLocalStorage = {
  name: 'app.Contacts.LocalStorage',
  component: 'app.Contacts',

  // We will use local storage to persist the data. This could be easily swapped out to use Firebase
  // or some other store. Or, you could create your own custom store to interact with a GraphQL/REST
  // API.
  store: {
    component: 'LocalStorageStore',
    storeName: 'contactsLocalStorage',
  },
};
