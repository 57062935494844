export const changePassword = {
  name: 'app.ChangePassword',
  component: 'UpdatePasswordEditor',
  updatePasswordBaseForm: {
    component: 'app.User',
  },
  store: {
    component: 'app.UserStore',
  },
  listeners: [
    {
      event: ['didSave', 'cancel'],
      actions: [
        {
          component: 'Redirect',
          path: '/account',
        },
      ],
    },
  ],
};
