export const contactsMySQL = {
  name: 'app.Contacts.MySQL',
  component: 'app.Contacts',

  // TODO: enable sorting
  'fields.collection.forbidSort': true,

  store: {
    component: 'RecordStore',
    storeName: 'app.Contacts.MySQLRecord',
  },
};
