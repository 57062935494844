export const menuItems = [
  {
    // path: '/form-builder',
    path: '/',
    label: 'Form Builder',
    content: {
      component: 'app.FormBuilder',
    },
  },
  // {
  //   // The route to the list of contacts
  //   path: '/contacts',
  //   label: 'Contacts',
  //   items: [
  //     {
  //       path: '/contacts-local-storage',
  //       label: 'Contacts: LocalStorage',
  //       content: {
  //         component: 'app.Contacts.LocalStorage',
  //       },
  //     },
  //     {
  //       path: '/contacts-generated-backend',
  //       label: 'Contacts: Generated Backend',
  //       content: {
  //         component: 'app.Contacts.GeneratedBackend',
  //       },
  //     },
  //     {
  //       path: '/contacts-dynamodb',
  //       label: 'Contacts: DynamoDB',
  //       content: {
  //         component: 'app.Contacts.DynamoDB',
  //       },
  //     },
  //     {
  //       path: '/contacts-dynamodb-connector',
  //       label: 'Contacts: DynamoDB Connector',
  //       content: {
  //         component: 'app.Contacts.DynamoDBConnector',
  //       },
  //     },
  //     {
  //       path: '/contacts-mysql',
  //       label: 'Contacts: MySQL',
  //       content: {
  //         component: 'app.Contacts.MySQL',
  //       },
  //     },
  //     {
  //       path: '/contacts-mysql-connector',
  //       label: 'Contacts: MySQL Connector',
  //       content: {
  //         component: 'app.Contacts.MySQLConnector',
  //       },
  //     },
  //   ],
  //   roles: ['admin', 'standard'],
  // },

  // {
  //   path: '/users',
  //   label: 'Users',
  //   content: {
  //     component: 'app.Users',
  //   },
  //   roles: ['admin'],
  // },

  // {
  //   label: 'My Account',
  //   items: [
  //     {
  //       path: '/account',
  //       label: 'Account',
  //       content: {
  //         component: 'app.ViewAndEditAccount',
  //       },
  //     },
  //     {
  //       path: '/account/change-password',
  //       label: 'Change Password',
  //       content: {
  //         component: 'app.ChangePassword',
  //       },
  //     },
  //   ],
  //   roles: ['admin', 'standard'],
  // },

  // {
  //   path: '/public',
  //   hidden: true,
  //   items: [
  //     {
  //       path: '/',
  //       content: {
  //         component: 'Action',
  //         actions: [
  //           {
  //             component: 'RedirectByRole',
  //             routes: [
  //               {
  //                 roles: ['admin'],
  //                 path: '/users',
  //               },
  //               {
  //                 path: '/contacts',
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       // Force the user to be logged in before the actions above are executed
  //       roles: ['admin', 'standard'],
  //     },
  //     {
  //       path: '/login',
  //       label: 'Login',
  //       content: {
  //         component: 'Card',
  //         title: 'Login',
  //         content: {
  //           component: 'app.Login',
  //         },
  //       },
  //       fullScreen: true,
  //     },
  //     {
  //       path: '/signup',
  //       label: 'Signup',
  //       content: {
  //         component: 'Card',
  //         title: 'Signup',
  //         content: {
  //           component: 'app.UserSignup',
  //         },
  //       },
  //       fullScreen: true,
  //     },
  //     {
  //       path: '/recover-password',
  //       content: {
  //         component: 'Card',
  //         title: 'Recover Password',
  //         content: {
  //           component: 'app.RecoverPassword',
  //         },
  //       },
  //       fullScreen: true,
  //     },
  //     {
  //       path: '/reset-password/:token',
  //       content: {
  //         component: 'Card',
  //         title: 'Reset Password',
  //         content: {
  //           component: 'app.ResetPassword',
  //         },
  //       },
  //       fullScreen: true,
  //     },
  //     {
  //       path: '/contact-us',
  //       content: {
  //         component: 'Card',
  //         title: 'Contact Us',
  //         content: {
  //           component: 'app.PublicContactUs',
  //         },
  //       },
  //       fullScreen: true,
  //     },
  //   ],
  // },

  // {
  //   path: '/contact',
  //   label: 'Contact',
  //   content: {
  //     component: 'app.ContactUs',
  //   },
  //   roles: ['admin', 'standard'],
  // },

  // {
  //   path: '/logout',
  //   label: 'Logout',
  //   content: {
  //     component: 'Action',
  //     actions: [
  //       {
  //         component: 'LogOutOfApp',
  //       },
  //       {
  //         component: 'Redirect',
  //         path: '/login',
  //       },
  //     ],
  //   },
  //   roles: ['admin', 'standard'],
  // },
];
