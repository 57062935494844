const text = `
To host this form yourself:

1. \`git clone https://github.com/mson-co/form-fields-starter\`
2. \`cd form-fields-starter\`
3. Modify \`src/App.js\` and replace the \`definition\` with the one below
3. \`yarn start\`
`;

export const formBuilder = {
  name: 'app.FormBuilder',
  component: 'FormBuilder',

  'fields.form.form.fields.fields.store': {
    component: 'LocalStorageStore',
    storeName: 'myForm',
  },

  'fields.export.content.items.header.content': {
    component: 'Alert',
    severity: 'info',

    styles: {
      component: 'Styles',
      marginTop: 2,
    },

    content: {
      component: 'Text',
      text,
    },
  },
};
