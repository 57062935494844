export const contactUsWrapper = {
  name: 'app.ContactUsWrapper',
  component: 'Form',
  schema: {
    component: 'Form',
    fields: [
      {
        name: 'to',
        component: 'TextField',
      },
    ],
  },
  to: '"Support" <support@example.com>',
  // body: 'header<br/>{{fields.body.value}}<br/>footer',
};
