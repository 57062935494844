// @ts-ignore
import Client from 'mson-server/lib/client';

// @ts-ignore
import registrar from 'mson/lib/compiler/registrar';

const client = new Client({
  url: 'http://localhost:4000/graphql',
});

// TODO: remove any after registrar migrated to typescript
(registrar as any).client = client;
