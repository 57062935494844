export const publicContactUs = {
  name: 'app.PublicContactUs',
  component: 'app.ContactUsWrapper',
  componentToWrap: {
    component: 'ContactUs',
    store: {
      component: 'RecordStore',
      storeName: 'app.PublicContactUs',
    },
  },
};
