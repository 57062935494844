import { menuItems } from './menu-items';

export const app = {
  name: 'app.App',
  component: 'App',
  menu: {
    component: 'Menu',
    header: {
      component: 'Text',
      text: '![FormField](/form-fields-logo.svg)',
    },
    items: menuItems,
  },
  listeners: [
    {
      event: 'loggedOut',
      actions: [
        {
          component: 'Redirect',
          path: '/login',
        },
      ],
    },
  ],
};
