export const contactUs = {
  name: 'app.ContactUs',
  component: 'app.ContactUsWrapper',
  componentToWrap: {
    component: 'AuthenticatedContactUs',
    store: {
      component: 'RecordStore',
      storeName: 'app.ContactUs',
    },
  },
  access: {
    form: {
      create: ['standard'],
    },
  },
};
