import { loadAndCompileApp } from './mson-app';

// @ts-ignore
import { render } from 'mson-react';

import './register-client';

// Render the app
const loadAndRenderApp = async () => {
  // Set to false to load during run time
  const loadStatically = true;

  const app = await loadAndCompileApp(loadStatically);
  render(app);
};
loadAndRenderApp();
