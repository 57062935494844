// Uses a RecordList to display a list of contacts
export const contacts = {
  name: 'app.Contacts',
  component: 'RecordList',
  label: 'Contacts',

  baseFormFactory: {
    // This factory creates Contacts
    component: 'Factory',
    product: {
      component: 'app.Contact',
    },
  },
};
