export const viewAndEditAccount = {
  name: 'app.ViewAndEditAccount',
  component: 'RecordEditor',
  baseForm: {
    component: 'app.User',
  },
  label: 'Account',
  store: {
    component: 'app.UserStore',
  },
  storeWhere: {
    userId: '{{globals.session.user.id}}',
  },
};
