export const login = {
  name: 'app.Login',
  component: 'Login',
  listeners: [
    {
      event: 'signUp',
      actions: [
        {
          component: 'Redirect',
          path: '/signup',
        },
      ],
    },
    {
      event: 'forgotPassword',
      actions: [
        {
          component: 'Redirect',
          path: '/recover-password',
        },
      ],
    },
    {
      event: 'contact',
      actions: [
        {
          component: 'Redirect',
          path: '/contact-us',
        },
      ],
    },
  ],
};
