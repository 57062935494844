export const contactsDynamoDB = {
  name: 'app.Contacts.DynamoDB',
  component: 'app.Contacts',

  // TODO: support sorting via secondary indexes:
  // https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/LSI.html#LSI.Using
  'fields.collection.forbidSort': true,

  store: {
    component: 'RecordStore',
    storeName: 'app.Contacts.DynamoDBRecord',
  },
};
