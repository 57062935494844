// A contact component that captures the user's first name, last name and email address
export const contact = {
  name: 'app.Contact',
  component: 'Form',
  fields: [
    {
      name: 'firstName',
      component: 'TextField',
      label: 'First Name',
      required: true,

      // When block is false, the next field will appear on the same line, i.e. the first name and
      // last name will be on the same line
      block: false,
    },
    {
      name: 'lastName',
      component: 'TextField',
      label: 'Last Name',
    },
    {
      name: 'email',
      component: 'EmailField',
      label: 'Email',
    },
  ],
};
